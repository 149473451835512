<script setup lang="ts">
import ButtonSecondary from '@/components/ButtonSecondary.vue'
import { navigateToCoreUrl } from '@/helpers/routing'
import { faArrowLeft, faPlus } from '@fortawesome/pro-regular-svg-icons'
import HeroSplash from '@/components/hero-splash.vue'
import VerticallyCentered from '@/components/vertically-centered.vue'
import splashImageUrl from '@/assets/assets/meters/splash.svg'
import AddEditMeterModal from '@/views/assets/meters/add-edit-meter-modal.vue'
import { computed, ref, toRef, toRefs } from 'vue'
import type { ComponentProps } from '@/types'
import { useLinkedMeters, useUnlinkedMeters } from '@/composables/assets/meters'
import ContentLoader from '@/components/content-loader.vue'
import DataTable from '@/components/data-table.vue'
import DataTableHeader from '@/components/data-table-header.vue'
import DataTableCell from '@/components/data-table-cell.vue'
import type { LinkedMeter } from '@/features/assets/schemas'
import { format } from 'date-fns/format'
import { DateFormats, formatNumber } from '@/helpers/formatting'
import DropdownContext from '@/components/DropdownContext.vue'
import DropdownItem from '@/components/DropdownItem.vue'
import HeroSplashError from '@/components/hero-splash-error.vue'
import ButtonPrimary from '@/components/ButtonPrimary.vue'
import { meterBillingRegisters, MeterStatus, meterTypes } from '@/features/assets/constants'

const props = defineProps<{ assetCode: number }>()
const { assetCode } = toRefs(props)

const addEditModalProps = ref<ComponentProps<typeof AddEditMeterModal>>()

const { data: unlinkedMeters } = useUnlinkedMeters(assetCode)
const { data: linkedMeters, isFetching, isError } = useLinkedMeters(assetCode)

const linkedMetersArray = computed(() => linkedMeters.value ?? [])
</script>

<template>
  <div class="mb-4">
    <ButtonSecondary :icon="faArrowLeft" @click="navigateToCoreUrl('Operations/AssetManagementDashboard')">Back to Assets</ButtonSecondary>
    <ButtonPrimary class="ms-2" v-if="(isError || linkedMetersArray.length > 0)  && (unlinkedMeters ?? []).length > 0" :icon="faPlus" @click="addEditModalProps = { assetCode }">Link Meter</ButtonPrimary>
  </div>

  <ContentLoader :loading="isFetching" variant="table">
    <HeroSplashError v-if="isError" />

    <DataTable v-else-if="linkedMetersArray.length > 0"
               :records="linkedMetersArray"
               :row-attrs="record => ({ class: { [$style['non-operational']]: record.meterStatus !== MeterStatus.Operational } })"
               :inactive-indexes="linkedMetersArray.filter(x => x.meterStatus !== MeterStatus.Operational).keys()">
      <template #headers>
        <DataTableHeader>Device Name</DataTableHeader>
        <DataTableHeader>Device ID</DataTableHeader>
        <DataTableHeader>Type</DataTableHeader>
        <DataTableHeader>Serial No.</DataTableHeader>
        <DataTableHeader>Model</DataTableHeader>
        <DataTableHeader>Status</DataTableHeader>
        <DataTableHeader>Billing Register</DataTableHeader>
        <DataTableHeader>Commissioning Date & Time</DataTableHeader>
        <DataTableHeader>Take-On Reading</DataTableHeader>
        <DataTableHeader>Decommissioning Date & Time</DataTableHeader>
        <DataTableHeader>Close-Out Reading</DataTableHeader>
        <DataTableHeader>Vendor</DataTableHeader>
        <DataTableHeader>Description</DataTableHeader>
      </template>
      <template #record="{ record } : { record: LinkedMeter }">
        <DataTableCell>{{ record.name }}</DataTableCell>
        <DataTableCell>{{ record.deviceId ?? '-' }} ({{ record.vcomDeviceId ?? '-' }})</DataTableCell>
        <DataTableCell>{{ meterTypes.find(x => x.value === record.meterType)?.text ?? '-' }}</DataTableCell>
        <DataTableCell>{{ record.serialNumber ?? '-' }}</DataTableCell>
        <DataTableCell>{{ record.model ?? '-' }}</DataTableCell>
        <DataTableCell>{{ record.meterStatus ?? '-' }}</DataTableCell>
        <DataTableCell>{{ meterBillingRegisters.find(x => x.value === record.billingRegisterId)?.text ?? '-' }}</DataTableCell>
        <DataTableCell>{{ record.commissioning ? format(record.commissioning, DateFormats.UserFriendly) : '-' }}</DataTableCell>
        <DataTableCell>{{ record.takeOnReading !== null ? formatNumber(record.takeOnReading) : '-' }}</DataTableCell>
        <DataTableCell>{{ record.deCommissioning ? format(record.deCommissioning, DateFormats.UserFriendly) : '-' }}</DataTableCell>
        <DataTableCell>{{ record.closeOutReading !== null ? formatNumber(record.closeOutReading) : '-' }}</DataTableCell>
        <DataTableCell>{{ record.vendor }}</DataTableCell>
        <DataTableCell>{{ record.description }}</DataTableCell>
      </template>
      <template #context="{ record }">
        <DropdownContext>
          <DropdownItem @click="addEditModalProps = { assetCode, meterId: record.id }">Edit</DropdownItem>
        </DropdownContext>
      </template>
    </DataTable>

    <VerticallyCentered v-else>
      <HeroSplash
        title="Connect Your Meters, Unleash Insights!"
        sub-title="Link meters for usage visibility, accurate invoicing, and informed decisions."
        action-button-text="Link Meter"
        :action-button-icon="faPlus"
        :image-url="splashImageUrl"
        @actioned="addEditModalProps = { assetCode }"
      />
    </VerticallyCentered>
  </ContentLoader>

  <AddEditMeterModal v-if="addEditModalProps" v-bind="addEditModalProps" @close="addEditModalProps = undefined" />
</template>

<style module lang="scss">
@import "@/assets/variables";

.non-operational {
  --bs-table-color-state: $table-cell-inactive-color;
  color: $table-cell-inactive-color;
}
</style>
