import { QueryClient, useMutation, useQuery, useQueryClient } from "@tanstack/vue-query";
import { type MaybeRefOrGetter, toValue, type MaybeRef } from 'vue'
import {
  approveInvoice,
  cancelInvoice,
  getInvoiceActivity,
  getPaginatedInvoices,
  recreateInvoice,
  regenerateInvoice,
  sendInvoice,
  getInvoiceConfig,
  saveInvoiceConfig,
  getInvoice,
  saveCharges,
  updateInvoiceConfig,
  updateInvoiceMeterReadings,
  getInvoiceMeters
} from '@/features/billing/invoices'
import type { ComposableOptions, SortedField } from '@/types'
import { InvoiceStatus } from '@/features/billing/constants'
import { createLogger } from "@/helpers/logging";

type DataTypeForUpdate = Awaited<ReturnType<typeof getPaginatedInvoices>>
type PaginatedInvoiceWithUrls = DataTypeForUpdate['records'][number]

export function useCancelInvoice() {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (invoiceId: MaybeRefOrGetter<number>) => cancelInvoice(toValue(invoiceId)),
    onSuccess(data, variables) {
      updateInvoiceInListedInvoiceQueryCache(
        queryClient,
        variables,
        invoice => ({ ...invoice, status: InvoiceStatus.Cancelled })
      )

      queryClient.invalidateQueries({ queryKey: ['invoices:activity', variables] }).catch(() => { })
    },
  })
}


export function useSendInvoice() {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (invoiceId: MaybeRefOrGetter<number>) => sendInvoice(toValue(invoiceId)),
    onSuccess(data, variables) {
      updateInvoiceInListedInvoiceQueryCache(
        queryClient,
        variables,
        invoice => ({ ...invoice, status: InvoiceStatus.Sent })
      )

      queryClient.invalidateQueries({ queryKey: ['invoices:activity', variables] }).catch(() => { })
    },
  })
}


export function useApproveInvoice() {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (invoiceId: MaybeRefOrGetter<number>) => approveInvoice(toValue(invoiceId)),
    onSuccess(data, variables) {
      updateInvoiceInListedInvoiceQueryCache(
        queryClient,
        variables,
        invoice => ({ ...invoice, status: InvoiceStatus.Approved })
      )

      queryClient.invalidateQueries({ queryKey: ['invoices:activity', variables] }).catch(() => { })
    },
  })
}


export const useRecreateInvoice = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (invoiceId: MaybeRefOrGetter<number>) => recreateInvoice(toValue(invoiceId)),
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: ['invoices:list' ]}).catch(() => { })
    }
  })
}

export function useRegenerateInvoice() {
  return useMutation({
    mutationFn: (invoiceId: MaybeRefOrGetter<number>) =>
      regenerateInvoice(
        toValue(invoiceId)
      )
  })
}


export function useInvoiceActivity(invoiceId: MaybeRefOrGetter<number>) {
  return useQuery({
    queryKey: ['invoices:activity', invoiceId],
    meta: {
      logger: createLogger(`composables/billing/useInvoiceActivity`)
    },
    queryFn: () => getInvoiceActivity(toValue(invoiceId))
  })
}


export function usePaginatedInvoices(
  options: ComposableOptions<{
    page: number
    pageSize: number
    sorting: SortedField[]
    filter: string
    customerIds: number[]
    statuses: InvoiceStatus[]
    startAt?: Date
    endAt?: Date
  }>
) {
  return useQuery({
    queryKey: ['invoices:list', options],
    placeholderData: previous => previous,
    meta: {
      logger: createLogger(`composables/billing/usePaginatedInvoices`)
    },
    queryFn: () =>
      getPaginatedInvoices({
        page: toValue(options.page),
        pageSize: toValue(options.pageSize),
        sort: toValue(options.sorting),
        filter: toValue(options.filter),
        startAt: toValue(options.startAt),
        endAt: toValue(options.endAt),
        statuses: toValue(options.statuses),
        customerIds: toValue(options.customerIds)
      })
  })
}

function updateInvoiceInListedInvoiceQueryCache(queryClient: QueryClient, invoiceId: MaybeRefOrGetter<number>, updater: (invoice: PaginatedInvoiceWithUrls) => PaginatedInvoiceWithUrls) {
  const invoiceIdValue = toValue(invoiceId)

  for (const [key, data] of queryClient.getQueriesData<DataTypeForUpdate>({ queryKey: ['invoices:list'] })) {
    if (!data) {
      continue
    }

    const matchedIndex = data.records.findIndex(x => x.id === invoiceIdValue)

    if (matchedIndex < 0) {
      console.log(`updating cancelInvoice - no invoice with id ${invoiceIdValue} found for in data for query key ${JSON.stringify(key)}`)

      continue
    }

    queryClient.setQueryData<DataTypeForUpdate>(
      key,
      old => old
        ? {
          ...data,
          records: [...data.records].map(
            invoice => invoice.id === invoiceIdValue ? updater({ ...invoice }) : invoice
          )
        }
        : old
    )
  }
}

export function useGetInvoiceConfig(assetCode: MaybeRefOrGetter<number | undefined>) {

  return useQuery({
    queryKey: ['invoices:config', assetCode],
    meta: {
      logger: createLogger(`composables/billing/useGetInvoiceConfig`)
    },
    queryFn: () => getInvoiceConfig(toValue(assetCode)!),
     enabled() {
      const value = toValue(assetCode)
      return value !== undefined && value > 0
    }
  })
}


type BillingConfig = {
  id: number,
  showMeterReadings: MaybeRefOrGetter<boolean>,
  showSavings: MaybeRefOrGetter<boolean>
}

export const useUpdateAssetInvoiceConfig = (assetCode: MaybeRefOrGetter<number | undefined>) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: ({ id, showMeterReadings, showSavings } : BillingConfig) => saveInvoiceConfig(toValue(assetCode)!, {
      id: id,
      showMeterReadings: toValue(showMeterReadings),
      showSavings: toValue(showSavings)
    }),
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: ['invoices:config',assetCode]}).catch(() => { })
    }
  })
}

export function useGetInvoice (invoiceId: number){

  return useQuery({
    queryKey: ['invoices',invoiceId],
    placeholderData: previous => previous,
    meta: {
      logger: createLogger(`composables/billing/useGetInvoice`)
    },
    queryFn: () => getInvoice(invoiceId)
  })
}

type InvoiceCharge = {
  id: number,
  quantity: number
}

export function useSaveCharges(invoiceId : number){
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (invoiceCharges : InvoiceCharge []) => saveCharges(invoiceId,invoiceCharges),
    onSuccess(){
      queryClient.invalidateQueries({ queryKey: ['invoices',invoiceId]})
    }
  })
}

type InvoiceConfig = {
  invoiceId: number,
  showMeterReadings: MaybeRef<boolean>,
  showSavings: MaybeRef<boolean>,
  poNumber: MaybeRef<string>
}

export function useUpdateInvoiceConfig(invoiceId: number){

  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (invoiceConfig: InvoiceConfig) => updateInvoiceConfig({
      invoiceId: invoiceConfig.invoiceId,
      showMeterReadings: toValue(invoiceConfig.showMeterReadings),
      showSavings: toValue(invoiceConfig.showSavings),
      poNumber: toValue(invoiceConfig.poNumber)
    })
  })
}

type InvoiceMeterReading = {
  id: number,
  deviceId: string,
  forwardActiveStartReading: number,
  forwardActiveEndReading: number,
  reverseActiveStartReading: number,
  reverseActiveEndReading: number,
  hide: boolean
}

export function useUpdateInvoiceMeterReadings(invoiceId: number, updatingConfig: boolean=true) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (invoiceMeterReadings: InvoiceMeterReading[]) => {

      await updateInvoiceMeterReadings(invoiceId, invoiceMeterReadings, updatingConfig)
    },
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: ['invoices', invoiceId] }).catch(() => {});
      queryClient.invalidateQueries({ queryKey: ['invoices:list' ]}).catch(() => { })
    }
  });
}

export function useGetInvoiceMeterReadings(invoiceId: number) {
  return useQuery({
    queryKey: ['invoices:meterReading',invoiceId],

    queryFn: () => getInvoiceMeters(invoiceId)
  })
}
