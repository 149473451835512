<script setup lang="ts">
import {
    faArrowLeft,
    faCircleInfo,
    faFloppyDisk,
    faXmark, faTriangleExclamation
} from "@fortawesome/pro-regular-svg-icons";
import { useAssetSettings, useAssetSettingsLookups, useSaveAssetSettings, useAssetCustomers } from "@/composables/assets/assets";
import { computed, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import NativeModal from "@/components/ui/modals/NativeModal.vue";
import { faCheck } from "@fortawesome/pro-regular-svg-icons";
import Button from "@/components/ButtonSecondary.vue";
import ButtonPrimary from "@/components/ButtonPrimary.vue";
import InvoiceDetailCard from "@/views/assetSetup/invoice-details-card.vue"
import TariffProfileCard from "@/views/assetSetup/tariff-profile-card.vue";
import BillingSettingsCard from "@/views/assetSetup/billing-settings-card.vue"
import ContractTermsCard from "@/views/assetSetup/contract-terms-card.vue"
import UtilityProfileCard from "@/views/assetSetup/utility-profile-card.vue"
import InvoiceTemplateCard from "@/views/assetSetup/invoice-template-card.vue"
import InvoiceContactCard from "@/views/assetSetup/invoice-contact-card.vue"
import { type LookupSettings } from "@/features/assets/schemas";
import { type Contact } from "@/features/contacts/schemas";
import routeNames from "@/router/names";
import { useForm } from "vee-validate";
import { z } from "zod";
import { toTypedSchema } from "@vee-validate/zod";
import useLoader from "@/composables/loader";
import useNotifications from "@/composables/notifications";
import { useUpdateContacts } from '@/composables/contacts/contacts';
import { ExportPowerEscalationType } from "@/features/billing/constants";
import { useSaveTariffs } from "@/composables/billing/tariffs";
import { useAvailableTariffs, useTariffsForAsset } from "@/composables/billing/tariffs";
import { navigateToCoreUrl } from "@/helpers/routing";
import { mainCurrency } from '@/helpers/validation'
import { createLogger } from '@/helpers/logging'
import { DeliveryStatus, MeterBillingType } from '@/features/assets/constants'
import { useContacts } from '@/composables/contacts/contacts';
import { CONTACT_TYPES } from "@/features/constants";


const logger = createLogger('views:assetSetup/setup.vue')
const { wrap: wrapInLoader } = useLoader()
const { addError, addSuccess, addWarning } = useNotifications()
const route = useRoute();
const router = useRouter();
const contractFundEntityId = ref<number>();
const selectedCustomerId = ref<string | null>();
const showGlobalError = ref<boolean>(false);

const assetCode = ref<number>(parseInt(route.params.assetCode.toString()))
const { mutateAsync: mutateContactsAsync } = useUpdateContacts(assetCode);
const { data: assetData, isError,error:assetDataError } = useAssetSettings(assetCode);
const { data: assetLookup, isError:isLookupsError, error:lookupsError  } = useAssetSettingsLookups();
const { isError:isContactsError, error: contactError } = useContacts(assetCode,CONTACT_TYPES.invoice);
const { isError: isTariffsError, error: tariffsError } = useAvailableTariffs()
const { isError: isAssetTariffsError, error: assetTariffsError } = useTariffsForAsset(assetCode)
const { mutateAsync } = useSaveAssetSettings(assetCode)
const { mutateAsync: mutateTariffsAsync } = useSaveTariffs(assetCode)
const { data: customers,isError:isCustomerError, error:customerError } = useAssetCustomers(contractFundEntityId);
const showModalValidation = ref(false);
const showModalLeave = ref(false);
const showModalLeaveTwo = ref(false);
var contacts:Contact[]
const isExportingPower = ref(false)
const isAutoApproving = ref(false)

const { handleSubmit } = useForm({
  initialValues: {
    tariffProfile: {
      rates: {}
    }
  },
  validationSchema: computed(
    () => toTypedSchema(
      z.object({
        billingSettings: z.object({
          customerTag: z.string().nullable(),
          meterBillingType: z.nativeEnum(MeterBillingType),
          contractStartDate: z.date().nullable(),
          fundTransferDate: z.date().nullable(),
          firstEscalationMonth: z.number().int().gte(1).lte(12),
          firstEscalationYear: z.number().int().gte(new Date().getFullYear() - 6).lte(new Date().getFullYear() + 10),
          paymentDueInDays: z.number().int().gte(0),
          autoApprove: z.boolean(),
          gridExportValidation: isAutoApproving.value ? z.boolean() : z.boolean().optional(),
          expectedEnergyVariance: isAutoApproving.value ? z.number().int() : z.number().int().optional(),
          billingCycle: z.object({
            id: z.number()
          })
        }),
        utilityProvider: z.object({
          blendedTariff: mainCurrency(z.number()),
        }),
        contractTerms: z.object({
          assetDrawerNumber: z.number().nullish().optional(),
          degradationType: z.number().default(1),
        }),
        invoiceDetails: z.object({
          contractFundEntity: z.object({ id: z.number() }),
          customer: z.object({ customerExternalIdentifier: z.string() }),
        }),
        tariffProfile: z.object({
          tariffStructureId: z.number().int().gt(0),
          escalationDate: isExportingPower.value ? z.date() : z.date().optional(),
          escalationValue: isExportingPower.value ? z.number() : z.number().optional(),
          escalationType: isExportingPower.value ? z.nativeEnum(ExportPowerEscalationType) : z.nativeEnum(ExportPowerEscalationType).optional(),
          rates: z.record(
            z.string(),
            z.object({
              pvRate: mainCurrency(z.number()),
              exportRate: isExportingPower.value ? mainCurrency(z.number()) : mainCurrency(z.number()).optional(),
            })
          ).refine(
            rates => Object.keys(rates).length > 0,
            'At least one rate is required.'
          )
        })
      })
    )
  )
})

defineOptions({ name: 'AssetSetup' })


const updateInvoiceContact = (details: Contact[]) => {

    contacts = details.map(contact => ({ ...contact }));
}

const acceptAndSave = async () => {
    showModalValidation.value = false
    await validateAndSave();
    router.push({ name: routeNames.invoiceSetup, params: { assetCode: assetCode.value } })
}
const contactsCustomValidation =()=>{
    if(contacts.length==0){
      addError({ title: 'You need to add atleast one contact.' })
      return false
    } else if(contacts.length>=1 && contacts.filter(x=>{return x.primaryContact}).length==0){
     addError({ title: 'You need to set primary contact.' })
     return false
    }
    return true
}
const validateAndSave = handleSubmit(
  async values => {
    try {
      if(!contactsCustomValidation())
            return;
      const settings = assetData.value!
      contractFundEntityId.value =values.invoiceDetails.contractFundEntity.id;
      selectedCustomerId.value =values.invoiceDetails.customer.customerExternalIdentifier;

      const billingSettings = { ...settings.billingSettings! }
      billingSettings.meterBillingType = values.billingSettings.meterBillingType
      billingSettings.customerTag = values.billingSettings.customerTag ?? null;
      billingSettings.contractStartDate = values.billingSettings.contractStartDate?? null;
      billingSettings.fundTransferDate = values.billingSettings.fundTransferDate?? null;
      billingSettings.firstEscalationMonth = new Date(`${values.billingSettings.firstEscalationYear}-${('0' + values.billingSettings.firstEscalationMonth.toString()).split('').slice(-2).join('')}-01T00:00:00Z`)
      billingSettings.paymentDueInDays = values.billingSettings.paymentDueInDays
      billingSettings.autoApprove = values.billingSettings.autoApprove

      if (values.billingSettings.gridExportValidation !== undefined) {
        billingSettings.gridExportValidation = values.billingSettings.gridExportValidation||false
      }

      billingSettings.expectedEnergyVariance = values.billingSettings.expectedEnergyVariance || 0
      billingSettings.invoiceDate = 1
      billingSettings.exportPower = false
      billingSettings.exportPowerTariffInCents = 0
      billingSettings.billingCycle = assetLookup.value?.billingCycles.find((x: LookupSettings['billingCycles'][number]) => x.id === values.billingSettings.billingCycle.id) || null

      const utilityProvider = { ...settings.utilityProvider! }
      utilityProvider.blendedTariff = values.utilityProvider.blendedTariff

      const contractTerms = { ...settings.contractTerms! }
      contractTerms.assetDrawerNumber = values.contractTerms.assetDrawerNumber || 0
      contractTerms.degradationType = values.contractTerms.degradationType
      const invoiceDetails = { ...settings.invoiceDetails }
      invoiceDetails.contractFundEntity= assetLookup.value?.contractFundEntities.find(c=>c.id==values.invoiceDetails.contractFundEntity.id) ||null;
      invoiceDetails.customer= customers.value?.find(x=>x.customerExternalIdentifier ===selectedCustomerId.value)||null;
      const updates = await wrapInLoader(
        async () => Promise.allSettled([
            mutateTariffsAsync({
              tariffStructureId: values.tariffProfile.tariffStructureId,
              exportPower: isExportingPower.value,
              rates: Object.entries(values.tariffProfile.rates).map(
                ([tariffType, rates]) => ({ tariffTypeId: parseInt(tariffType, 10), pvRate: rates.pvRate, exportRate: rates.exportRate })
              ),
              escalationDate: values.tariffProfile.escalationDate,
              escalationType: values.tariffProfile.escalationType,
              escalationValue: values.tariffProfile.escalationValue,
            }),
            mutateContactsAsync(contacts),
            mutateAsync({
              assetCode: assetCode.value,
              projectCode: assetData.value!.projectCode,
              projectName: assetData.value!.projectName,
              invoiceDetails,
              // tariffProfile: {},
              deliveryStatus: assetData.value!.deliveryStatus,
              billingSettings:billingSettings||null,
              utilityProvider,
              contractTerms:contractTerms||null,
              contacts: [],
              invoiceTemplates: null,
            })
          ])
      )

      // All updates were successful.
      if (updates.every(x => x.status === 'fulfilled')) {
        addSuccess({ title: 'Asset settings updated successfully.' })
      } else if (updates.every(x => x.status === 'rejected')) {
        addError({ title: 'Failed to save asset settings.' })
      } else {
        addWarning({
          title: 'Asset settings were partially updated.',
          message: 'Some settings failed to save correctly. Please check your input and try again.'
        })
      }

    } catch (e: unknown) {
      addError({ title: 'Failed to save asset settings.' })
    }
  },
  errors => {
    logger.warning('Form still contains validation errors', { errors: errors.errors, values: errors.values })
  }
)
const goBack = () => {
    showModalLeaveTwo.value = false
    showModalLeave.value = false
    navigateToCoreUrl(`Operations/AssetManagementDashboard`)
};
watch([isError,isCustomerError,isLookupsError,isContactsError,isTariffsError,isAssetTariffsError], ([hasError,hasCustomerError,hasLookupsError,hasContactsError,hasTariffsError,hasAssetTariffsError]) => {
  showGlobalError.value=hasError||hasCustomerError||hasLookupsError||hasContactsError||hasTariffsError||hasAssetTariffsError;
}, { immediate: true });
const globalErrorMessage = computed(() => {
    return [customerError.value?.message??'',assetDataError.value?.message??'',lookupsError.value?.message??'',contactError.value?.message??'',tariffsError.value?.message??'', assetTariffsError.value?.message??''].join("\r\n");
});
</script>
<template>
    <NativeModal class="native-modal-confirmation" :value="showModalValidation">
        <div class="native-modal-confirmation--title">ENSURE ALL REQUIRED INFO IS PROVIDED</div>
        <div class="native-modal-confirmation--content-wrapper">
            <div class="native-modal-confirmation--content">
                <div class="d-flex gap-4 align-items-center">
                    <FontAwesomeIcon :icon="faTriangleExclamation" class="fa-4x color-temp-yellow" />
                    <span>The following should please be completed to proceed: </span>
                </div>
                <div class="d-flex flex-column gap-3 modal-validation">
                    <div>
                        <span>
                            <strong>STEP 1:</strong> Link Meters associated with this asset </span><br />
                        <span>
                            <strong>STEP 2:</strong> Complete all inputs on the Settings tab </span><br />
                        <span>
                            <strong>STEP 3:</strong> Setup the Invoice Template with all recurring charges </span><br />
                    </div>

                    <span class="">If meters were linked, they should auto-fill the Invoice Template. Review the
                        displayed information to confirm accuracy for future invoices.</span>
                </div>
            </div>
        </div>
        <div class="native-modal-confirmation--actions">
            <ButtonPrimary :icon="faCheck" @click="acceptAndSave" style="margin-left: 119px;" lass="color-temp-white">
                Okay</ButtonPrimary>
        </div>
    </NativeModal>
    <NativeModal class="native-modal-confirmation" :value="showModalLeave">
        <div class="native-modal-confirmation--title">ARE YOU SURE YOU WANT TO LEAVE THIS PAGE?</div>
        <div class="native-modal-confirmation--content-wrapper">
            <div class="native-modal-confirmation--content">
                <div class="d-flex gap-4">
                    <FontAwesomeIcon :icon="faTriangleExclamation" class="fa-4x color-temp-yellow" />
                    <span>If you cancel with unsaved information, it will be discarded, and you won’t be
                        able to recover it.<br /><br />
                        Do you want to proceed?
                    </span>
                </div>
            </div>
        </div>
        <div class="native-modal-confirmation--actions">
            <Button :icon="faXmark" @click="showModalLeave = false" class="color-temp-white">No</Button>
            <ButtonPrimary :icon="faCheck" @click="goBack" class="color-temp-white">Yes</ButtonPrimary>
        </div>
    </NativeModal>
    <NativeModal class="native-modal-confirmation" :value="showModalLeaveTwo">
        <div class="native-modal-confirmation--title">ARE YOU SURE YOU WANT TO LEAVE THIS PAGE?</div>
        <div class="native-modal-confirmation--content-wrapper">
            <div class="native-modal-confirmation--content">
                <div class="d-flex gap-4">
                    <FontAwesomeIcon :icon="faTriangleExclamation" class="fa-4x color-temp-yellow" />
                    <span>If you made changes, remember to save before navigating away, alternatively they will be
                        discarded.<br /><br />
                        Do you want to proceed?
                    </span>
                </div>
            </div>
        </div>
        <div class="native-modal-confirmation--actions">
            <Button :icon="faXmark" @click="showModalLeaveTwo = false" class="color-temp-white">No</Button>
            <ButtonPrimary :icon="faCheck" @click="goBack" class="color-temp-white">Yes</ButtonPrimary>
        </div>
    </NativeModal>
    <NativeModal class="native-modal-confirmation" :value="showGlobalError">
        <div class="native-modal-confirmation--title">OUR APOLOGIES</div>
        <div class="native-modal-confirmation--content-wrapper">
            <div class="native-modal-confirmation--content">
                <div class="d-flex gap-4">
                    <FontAwesomeIcon :icon="faTriangleExclamation" class="fa-4x color-temp-yellow" />
                    <span><b>Something went wrong.</b> <br/><br/>
                        Please try reloading the page, alternatively log the following error with our support team:<br/><br/>
                        {{globalErrorMessage}} <br/><br/>
                        Thank you for your patience.
                    </span>
                </div>
            </div>
        </div>
        <div class="native-modal-confirmation--actions">
            <ButtonPrimary :icon="faCheck" @click="showGlobalError=false" class="color-temp-white" style="width: 100px;">Okay</ButtonPrimary>
        </div>
    </NativeModal>
    <div class="wrapper">
        <div class="d-flex justify-content-between align-items-center top-items-wrapper">
            <div class="button-wrapper">
                <Button :icon="faArrowLeft" @click="showModalLeaveTwo = true" style="  width: 142px;">Back to Dashboard</Button>
            </div>
            <div class="d-flex align-items-center gap-2"
                v-if="assetData?.deliveryStatus?.name && assetData?.deliveryStatus?.name !== DeliveryStatus.Operational">
                <FontAwesomeIcon :icon="faCircleInfo" class="fa-4x color-temp-yellow" />
                <span>Complete these: <strong>1.</strong> Link Meters for this asset |
                    <strong>2.</strong> Enter all Asset Settings below | <strong>3.</strong> Setup
                    Invoice Template</span>
            </div>
        </div>
        <div class="assets-cards-layout" v-if="!showGlobalError">
            <div class="invoice-and-billing-wrapper">
                <InvoiceDetailCard :assetCode="assetCode" />
                <TariffProfileCard :asset-code="assetCode" @update:is-exporting-power="isExportingPower = $event" />
                <InvoiceTemplateCard :asset-code="assetCode" />
            </div>
            <div class="right-cards-wrapper">
                <div class="pv-profile-and-contract-terms">
                    <BillingSettingsCard :asset-code="assetCode" @update:auto-approve="isAutoApproving = $event" />
                    <div class="contract-terms-and-utility-profile">
                        <ContractTermsCard :asset-code="assetCode" />
                        <UtilityProfileCard :asset-code="assetCode" />
                    </div>
                </div>
                <div class="invoice-contact-wrapper">
                    <InvoiceContactCard :asset-code="assetCode" @update="updateInvoiceContact" />
                </div>
            </div>
        </div>
        <div class="button-wrapper mt-4" v-if="!showGlobalError">
            <ButtonPrimary :icon="faFloppyDisk" class="color-temp-white" @click="showModalValidation = true"
                v-if="assetData?.deliveryStatus?.name && assetData?.deliveryStatus?.name != 'Operational'">
                Save & Open
                Invoice Setup</ButtonPrimary>
            <Button :icon="faFloppyDisk" class="one-h-width" @click="validateAndSave">Save</Button>
            <Button :icon="faXmark" class="one-h-width" @click="showModalLeave = true">Cancel</Button>
        </div>
    </div>
</template>
<style scoped lang="scss">
.wrapper {
    .top-items-wrapper {
        margin-bottom: 17px;
        margin-right: 318px;
    }

    .title {
        font-size: 18px;
        font-weight: bold;
        text-transform: uppercase;
    }

    .one-h-width {
        width: 100px;
    }

    .tabs-wrapper {
        span {
            text-transform: uppercase;
            font-weight: bold;
            cursor: not-allowed;
            opacity: 50%;

            &.is-active {
                color: #28a09c;
                border-bottom: 2px solid #28a09c;
                cursor: pointer;
                opacity: 1;
            }
        }
    }

    .table-no-bottom-border .last-row {
        border-bottom: none;
        border-color: #fff;
    }

    thead tr th {
        font-size: 12px;
        font-weight: bold;
        color: #6d6e71;
    }

    .card-input-title {
        font-size: 12px;
        font-weight: bold;
        color: #6d6e71;
    }

    th {
        font-size: 12px;
        font-weight: normal;
        color: #6d6e71;
    }

    tr {
        border-color: #d1d1d1;
    }

    .sub-title {
        font-size: 13px;
        font-weight: bold;
        color: #6d6e71;
        text-transform: uppercase;
    }

    .gutter {
        margin: 10px 0;
    }

    .medium-input {
        width: 115px;
    }

    .input-currency {
        :deep(.form-control) {
            text-align: right !important;
        }

        :deep(input) {
            border-left: none;
            text-align: right;
        }

        :deep(.input-group-text) {
            color: #d1d1d1;
            background-color: #fff !important;
        }

        :deep(.input-group) {
            width: unset !important;
        }
    }

    .input-percentage {
        :deep(.form-control) {
            text-align: left;
        }

        :deep(input) {
            border-right: none;
        }

        :deep(.input-group-text) {
            color: #d1d1d1;
            background-color: #fff !important;
        }
    }

    .large-input {
        width: 173px;
    }

    .tabs-wrapper {
        display: flex;
        gap: 30px;
        margin-block: 27px 34px;

        .card-input-title {
            font-size: 12px;
            letter-spacing: 0px;
            color: #28a09c;
            text-transform: uppercase;
        }
    }

    .button-wrapper {
        display: flex;
        gap: 10px;
        margin-bottom: 24px;
    }

    .assets-cards-layout {
        display: flex;
        gap: 27px;
    }

    .invoice-and-billing-wrapper {
        display: flex;
        flex-direction: column;
    }

    .right-cards-wrapper {
        display: flex;
        flex-direction: column;

        .pv-profile-and-contract-terms {
            display: flex;
            gap: 27px;

            .contract-terms-and-utility-profile {
                display: flex;
                flex-direction: column;
            }
        }
    }
}

.color-temp-yellow {
    color: #fee351;
}

.native-modal-confirmation {
    .native-modal-confirmation--title {
        margin: -25px -25px 25px -25px;
        padding: 25px;
        border-bottom: 1px solid #ddd;
        font-size: 16px;
        font-weight: bold;
        text-transform: uppercase;
    }

    .native-modal-confirmation--content-wrapper {
        margin-top: 25px;
        display: flex;
        flex: 1 auto;
    }

    .native-modal-confirmation--content {
        margin-top: 0;

        &.with-icon {
            margin-left: 25px;
        }
    }

    .native-modal-confirmation--icon {
        font-size: 45px;
    }

    .native-modal-confirmation--actions {
        margin-top: 18px;
        margin-left: 208px;
        text-align: end;
        button {
            margin-left: 10px;
        }
    }

    .native-modal-confirm--action-confirm {
        margin-left: 10px;
    }
}

.modal-validation {
    margin-left: 73px;

    a {
        color: #28a09c;
    }
}
</style>
