import { buildApiUrl, Type } from "@/features/api";

export enum InvoiceStatus {
  ActionRequired = 1,
  Draft = 2,
  Approved = 3,
  Sent = 4,
  Cancelled = 5,
  Paid = 6,
}
export const invoiceStatusAsList = () => [
  { status: InvoiceStatus.ActionRequired, displayName: 'Action Required' },
  { status: InvoiceStatus.Approved, displayName: 'Approved' },
  { status: InvoiceStatus.Cancelled, displayName: 'Cancelled' },
  { status: InvoiceStatus.Draft, displayName: 'Draft' },
  { status: InvoiceStatus.Paid, displayName: 'Paid' },
  { status: InvoiceStatus.Sent, displayName: 'Sent' },
]


export const Urls = {
  exportInvoices: buildApiUrl(Type.AssetManagement, 'billing/invoices/exports').toString(),
} as const

export enum ExportPowerEscalationType {
  Currency = 1,
  Percentage = 2
}
export enum MeterType {
  SolarMeter = 1,
  GeneratorMeter = 2,
  GridMeter = 3,
  VirtualMeter = 4
}

export enum Measure {
  ForwardActiveEnergy,
  ReverseActiveEnergy
}

export enum TariffStructureType {
  FixedHourly = 1,
  FixedWinterSummer = 2,
  TimeOfUse = 3,
  FixedMonthly = 4
}

export enum DisplayOptions {
  Hide = 0,
  Show = 1
}
