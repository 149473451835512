<script setup lang="ts">
import { computed, ref, toRef, toValue, watch } from 'vue'
import InvoiceDetails from '@/views/billing/invoicing/InvoiceDetails.vue'
import { faFloppyDisk, faXmark, faArrowRight } from '@fortawesome/pro-regular-svg-icons'
import { useGetInvoiceConfig, useUpdateAssetInvoiceConfig } from '@/composables/billing/invoices'
import Button from "@/components/ButtonSecondary.vue"
import ButtonPrimary from "@/components/ButtonPrimary.vue"
import { useRoute, useRouter } from 'vue-router'
import { useGetProjectByAssetCode, useUpdatePoNumber } from '@/composables/assets/projects'
import { useSaveMeterConfiguration } from '@/composables/assets/assets'
import ContentLoader from '@/components/content-loader.vue'
import useLoader from '@/composables/loader'
import useNotifications from '@/composables/notifications'
import HeroSplashError from "@/components/hero-splash-error.vue"
import type { InvoiceMeterReadings } from '@/features/billing/schemas'
import { hide } from '@popperjs/core'
import routeNames from "@/router/names"

const route = useRoute()
const router = useRouter()
const assetCode = computed(() => parseInt(route.params.assetCode.toString(), 10))

const { data: invoiceConfigData, isFetching: invoiceConfigLoading, isError: invoiceConfigDataError } = useGetInvoiceConfig(assetCode)
const { data: projectData, isLoading: projectLoading} = useGetProjectByAssetCode(assetCode)
const { mutateAsync: updateInvoiceConfig } = useUpdateAssetInvoiceConfig(assetCode)
const { mutateAsync: updateProject } = useUpdatePoNumber(assetCode)
const {mutateAsync: updateMeterConfig } = useSaveMeterConfiguration(assetCode)

const { wrap } = useLoader()
const { addSuccess, addError } = useNotifications()

var project : {
    id: number,
    organisationId: number,
    projectCode: number,
    customerExternalIdentifier: string,
    poNumber: string,
    utilityProviderId: number,
    name: string,
    description: string,
    addressLineOne?: string,
    addressLineTwo: string,
    suburb: string,
    city: string,
    countryId: string,
    vatNo: string
}

type InvoiceMeterReading = {
    id: number,
    deviceId: string,
    meterType: number,
    meterTypeName: string,
    serialNumber: string,
    startReading: number,
    endReading: number,
    reverseStartReading: number,
    reverseEndReading: number,
    readingDifference: number,
    hide: boolean
}

const projectCode = ref<number>(projectData.value?.projectCode ?? 0)
const poNumber = ref<string>(invoiceConfigData.value?.poNumber ?? '')

var invoiceConfig = toValue(invoiceConfigData)

const billingConfig = {
    id: invoiceConfigData.value?.billingConfigurationId ?? 0,
    showMeterReadings: ref<boolean>(invoiceConfigData.value?.showMeterReadings ?? false),
    showSavings: ref<boolean>(invoiceConfigData.value?.showSavings ?? false)
}

const invoice = {
    id: invoiceConfig?.id ?? 0,
    billingConfigurationId: invoiceConfig?.billingConfigurationId ?? 0,
    assetCode: invoiceConfig?.assetCode,
    invoiceStatusId: invoiceConfig?.invoiceStatusId ?? 0,
    invoiceNumber: invoiceConfig?.invoiceNumber ?? "",
    invoiceDate: invoiceConfig?.invoiceDate ?? new Date(),
    invoiceStartDate: invoiceConfig?.periodStartDate ?? new Date(),
    invoiceEndDate: invoiceConfig?.periodEndDate ?? new Date(),
    dueDate: invoiceConfig?.dueDate ?? new Date(),
    totalAmountIncludingTax: invoiceConfig?.totalAmountIncludingTax ?? 0,
    totalAmountExcludingTax: invoiceConfig?.totalAmountExcludingTax ?? 0,
    tax: invoiceConfig?.tax ?? 0,
    periodSavings: invoiceConfig?.invoiceSavings?.amount ?? 0,
    savingsToDate: invoiceConfig?.totalSavingsToDate ?? 0,
    gridConsumption: invoiceConfig?.totalGridConsumption ?? 0,
    totalEnergyConsumption: invoiceConfig?.totalEnergyConsumption ?? 0,
    totalEnergyExported: invoiceConfig?.totalEnergyExported ?? 0,
    isExportPower: invoiceConfig?.isExportPower ?? false
}

var customer = {
    name: invoiceConfig?.customer?.name ?? "",
    customerExternalIdentifier: invoiceConfig?.customer?.customerExternalIdentifier ?? "",
    address: invoiceConfig?.customer?.addressLineOne ?? "",
    vatNumber: invoiceConfig?.customer?.vatNumber ?? ""
}

var owner= {
    id: invoiceConfig?.contractFundEntity.id ?? 0,
    name: invoiceConfig?.contractFundEntity.name ?? "",
    address: invoiceConfig?.contractFundEntity.address ?? "",
    vatNumber: invoiceConfig?.contractFundEntity.vatNumber ?? "",
    bankDetails: {
        accountName: invoiceConfig?.contractFundEntity.accountName ?? "",
        bank: invoiceConfig?.contractFundEntity.accountName ?? "",
        accountNumber: invoiceConfig?.contractFundEntity.accountNumber ?? "",
        branchCode: invoiceConfig?.contractFundEntity.branchCode ?? ""
    }
}

var charges  = invoiceConfig?.invoiceCharges ?
    invoiceConfig.invoiceCharges.map(invoiceCharge => ({
    id: invoiceCharge.id ?? 0,
    description: invoiceCharge.description ?? "",
    billingType: invoiceCharge.chargeType.name ?? "",
    quantity: invoiceCharge.quantity ?? 0,
    rate: invoiceCharge.rate ?? 0,
    total: (invoiceCharge.rate ?? 0) * (invoiceCharge.quantity ?? 0),
    isEditing: false
})) : []

var meterReadings = invoiceConfig?.meterReadings ? invoiceConfig?.meterReadings.map(meterReading => ({
    id: meterReading.id ?? 0,
    deviceId: meterReading.deviceId ?? "",
    meterType: meterReading.meterType.id,
    meterTypeName: meterReading.meterType.name,
    serialNumber: meterReading.serialNumber ?? "",
    startReading: meterReading.forwardActiveStartReading,
    endReading: meterReading.forwardActiveEndReading,
    reverseStartReading: meterReading.reverseActiveStartReading,
    reverseEndReading: meterReading.reverseActiveEndReading,
    readingDifference: meterReading.forwardActiveEndReading - meterReading.forwardActiveEndReading,
    hide: meterReading.hide
})) : []


watch(invoiceConfigData, (newInvoiceConfigData) => {

    if(newInvoiceConfigData == undefined)
        return
    // assetCode.value = newInvoiceConfigData.assetCode;
    billingConfig.id = newInvoiceConfigData.billingConfigurationId
    invoice.id = newInvoiceConfigData.id
    invoice.assetCode = newInvoiceConfigData.assetCode
    invoice.invoiceStatusId = newInvoiceConfigData.invoiceStatusId
    invoice.billingConfigurationId = newInvoiceConfigData.billingConfigurationId
    invoice.periodSavings = newInvoiceConfigData.invoiceSavings?.amount ?? 0
    invoice.invoiceNumber = newInvoiceConfigData.invoiceNumber ?? ""
    invoice.invoiceDate = newInvoiceConfigData.invoiceDate
    invoice.invoiceStartDate = newInvoiceConfigData.periodStartDate
    invoice.invoiceEndDate = newInvoiceConfigData.periodEndDate
    invoice.dueDate = newInvoiceConfigData.dueDate
    invoice.totalAmountExcludingTax = newInvoiceConfigData.totalAmountExcludingTax
    invoice.totalAmountIncludingTax = newInvoiceConfigData.totalAmountIncludingTax
    invoice.tax = newInvoiceConfigData.tax
    invoice.savingsToDate = newInvoiceConfigData.totalSavingsToDate
    invoice.gridConsumption = newInvoiceConfigData.totalGridConsumption
    invoice.totalEnergyConsumption = newInvoiceConfigData.totalEnergyConsumption
    invoice.totalEnergyExported = newInvoiceConfigData.totalEnergyExported ?? 0
    invoice.isExportPower = newInvoiceConfigData.isExportPower

    poNumber.value = newInvoiceConfigData.poNumber ?? ""
    billingConfig.showMeterReadings.value = newInvoiceConfigData.showMeterReadings
    billingConfig.showSavings.value = newInvoiceConfigData.showSavings

    customer.name = newInvoiceConfigData.customer?.name ?? ""
    customer.address = `${newInvoiceConfigData.customer?.addressLineOne}, ${newInvoiceConfigData.customer?.addressLineTwo}, ${newInvoiceConfigData.customer?.suburb}
                        ${newInvoiceConfigData.customer?.city}, ${newInvoiceConfigData.customer?.region}`
    customer.vatNumber = newInvoiceConfigData.customer?.vatNumber ?? ""
    customer.customerExternalIdentifier = newInvoiceConfigData.customer?.customerExternalIdentifier ?? ""

    owner.id = newInvoiceConfigData.contractFundEntity.id
    owner.name = newInvoiceConfigData.contractFundEntity.name
    owner.address = newInvoiceConfigData.contractFundEntity.address
    owner.vatNumber = newInvoiceConfigData.contractFundEntity.vatNumber
    owner.bankDetails = {
        accountName: newInvoiceConfigData.contractFundEntity.accountName,
        bank: newInvoiceConfigData.contractFundEntity.bank,
        accountNumber: newInvoiceConfigData.contractFundEntity.accountNumber,
        branchCode: newInvoiceConfigData.contractFundEntity.branchCode
    }

    charges = newInvoiceConfigData.invoiceCharges?.map(invoiceCharge => ({
        id: invoiceCharge.id ?? 0,
        description: invoiceCharge.description ?? "",
        billingType: invoiceCharge.chargeType.name ?? "",
        quantity: invoiceCharge.quantity ?? 0,
        rate: invoiceCharge.rate ?? 0,
        total: (invoiceCharge.rate ?? 0) * (invoiceCharge.quantity ?? 0),
        isEditing: false
    }))

    meterReadings = newInvoiceConfigData.meterReadings?.map(meterReading => ({
        id: meterReading.id ?? 0,
        deviceId: meterReading.deviceId ?? "",
        meterType: meterReading.meterType.id,
        meterTypeName: meterReading.meterType.name,
        serialNumber: meterReading.serialNumber ?? "",
        startReading: meterReading.forwardActiveStartReading,
        endReading: meterReading.forwardActiveEndReading,
        reverseStartReading: meterReading.reverseActiveStartReading,
        reverseEndReading: meterReading.reverseActiveEndReading,
        readingDifference: meterReading.forwardActiveEndReading - meterReading.forwardActiveStartReading,
        hide: meterReading.hide
    }))||[]

}, { immediate: true })

watch(projectData, (newProjectData) => {

    poNumber.value = newProjectData?.poNumber ?? ""
    projectCode.value = newProjectData?.projectCode ?? 0
})

const handleShowMeterReadingsUpdate = (value: boolean) => {
    billingConfig.showMeterReadings.value = value
}

const handleShowSavingsUpdate = (value: boolean) => {

    billingConfig.showSavings.value = value
}

const handlePoNumberUpdate = (newPoNumber: string) => {
    poNumber.value = newPoNumber
}

const handleMeterReadingsUpdate = (newMeterReadings: InvoiceMeterReading []) => {

    meterReadings = newMeterReadings
}

const updateBillingConfig = () => wrap(
    async () => {

    await updateInvoiceConfig(billingConfig)
    await updateProject({
        projectCode: projectCode,
        poNumber: poNumber})

    var meterDisplayConfig = meterReadings.map(meterReading => ({
        id: meterReading.id,
        hide: meterReading.hide
    }))

    await updateMeterConfig(meterDisplayConfig)
})
.then(
    () => addSuccess({title: 'Invoice Updated'}),
    () => addError({title: 'Unable to update invoice', message: 'Please try again'})
)

const navigateToInvoiceDashboard = () => {
    router.push({name: routeNames.assetInvoicesDashboard})
}

</script>

<template>
        <ContentLoader :loading="invoiceConfigLoading || projectLoading"></ContentLoader>
        <HeroSplashError v-if="invoiceConfigDataError" />
        <InvoiceDetails v-if="!invoiceConfigLoading && !invoiceConfigDataError" :is-dummy-invoice="true" heading="Tax Invoice Template"
                            :invoice="invoice"
                            :customer="customer"
                            :owner="owner"
                            :charges="charges"
                            :showMeterReadings="billingConfig.showMeterReadings.value"
                            :showSavings="billingConfig.showSavings.value"
                            :poNumber="poNumber"
                            :meterReadings="meterReadings"
                            @update:poNumber="handlePoNumberUpdate"
                            @update:showMeterReading="handleShowMeterReadingsUpdate"
                            @update:showSavings="handleShowSavingsUpdate"
                            @update:meter-readings="handleMeterReadingsUpdate">
        </InvoiceDetails>
        <div class="button-wrapper mt-4" v-if="!invoiceConfigLoading">
                <ButtonPrimary :icon="faFloppyDisk" class="color-temp-white" @click="updateBillingConfig">Save</ButtonPrimary>
                <Button :icon="faXmark" class="one-h-width" @click="navigateToInvoiceDashboard">Cancel</Button>
                <span class="thank-you-text">Thank you!</span>
        </div>
</template>

<style scoped lang="scss">
    .button-wrapper {
    display: flex;
    gap: 10px;
    margin-bottom: 24px;
    .thank-you-text {
      font-size: 30px;
      font-weight: bold;
      text-transform: uppercase;
      margin-left: auto;
    }
  }
</style>
