<template>
  <span :class="$style['page-subtitle']"><slot /></span>
</template>

<style module lang="scss">
@import "@/assets/variables";

.page-subtitle {
  //font-weight: normal;
  text-transform: none;
  color: #b4b4b4;
  //color: $gray-500;
}
</style>
