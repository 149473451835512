<script setup lang="ts">
import { computed, watch, ref, type UnwrapRef } from "vue";
import ContentLoader from "@/components/content-loader.vue";
import HeroSplashError from "@/components/hero-splash-error.vue";
import { faFloppyDisk, faRightLeft} from "@fortawesome/pro-regular-svg-icons";
import useNotifications from "@/composables/notifications";
import { useGetInvoiceMeterReadings, useUpdateInvoiceMeterReadings } from "@/composables/billing/invoices";
import  { type InvoiceMeterReadings } from "@/features/billing/schemas";
import InputNumber from "@/components/InputNumber.vue";
import ModalContainer from "@/components/modal/modal-container.vue";
import ModalTitle from "@/components/modal/modal-title.vue";
import ModalContent from "@/components/modal/modal-content.vue";
import ModalFooterConfirmation from "@/components/modal/modal-footer-confirmation.vue";
import { MeterType } from "@/features/billing/constants";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { useForm } from "vee-validate";
import { toTypedSchema } from "@vee-validate/zod";
import { z } from "zod";
import { roundToPrecision } from '@/helpers'

const props = defineProps<{ invoiceId: number; }>();
const { data, isLoading, isError } = useGetInvoiceMeterReadings(props.invoiceId);
const { mutateAsync,isPending } = useUpdateInvoiceMeterReadings(props.invoiceId,false);
const { addSuccess, addError } = useNotifications();
const emit = defineEmits({
  close: () => true,
});

const meters = ref<NonNullable<UnwrapRef<typeof data>>>([])

watch(data, (newData) => {
  if (newData === undefined) {
    return
  }

  meters.value = newData.map(c => ({ ...c, hide: false }))
}, { immediate: true })

const validationSchema = toTypedSchema(z.object({ meters: z.array(z.object({
  forwardActiveStartReading: z.number().gte(0, 'Missing data'),
  reverseActiveStartReading: z.number().gte(0, 'Missing data'),
  forwardActiveEndReading: z.number().gte(0, 'Missing data'),
  reverseActiveEndReading: z.number().gte(0, 'Missing data')
}))}));

const form = useForm({
  validationSchema
});
type InvoiceMeterReading = {
  id: number,
  deviceId: string,
  forwardActiveStartReading: number,
  forwardActiveEndReading: number,
  reverseActiveStartReading: number,
  reverseActiveEndReading: number,
  hide: boolean
}
const handleSave = form.handleSubmit(async (values) => {
  try {
    const meterReadings = meters.value.map(reading => ({
      id: reading.id,
      deviceId: reading.deviceId,
      forwardActiveStartReading: reading.forwardActiveStartReading,
      forwardActiveEndReading: reading.forwardActiveEndReading || 0,
      reverseActiveStartReading: reading.reverseActiveStartReading,
      reverseActiveEndReading: reading.reverseActiveEndReading || 0,
    } as InvoiceMeterReading));
    await mutateAsync(meterReadings);
    emit("close");
    addSuccess({ title: "Meter readings updated successfully" });
  } catch (error) {
    addError({ title: "Failed to update meter readings" });
  }
});

const computeTotal = (meterTypeId: number, readingType: string) => computed(() =>
  roundToPrecision(
    meters.value
      .filter(({ meterType }) => meterType.id === meterTypeId)
      .map((meter: InvoiceMeterReadings) => (meter as any)[`${readingType}EndReading`] - (meter as any)[`${readingType}StartReading`])
      .reduce((acc: number, current: number) => acc + current, 0),
    5
  )
);

const totalGridForward = computeTotal(MeterType.GridMeter, 'forwardActive');
const totalGridReverse = computeTotal(MeterType.GridMeter, 'reverseActive');
const totalPVForward = computed(() =>
  roundToPrecision(
    meters.value
      .filter(({ meterType }) => meterType.id === MeterType.SolarMeter || meterType.id === MeterType.VirtualMeter)
      .map((meter: InvoiceMeterReadings) => (meter as any)[`forwardActiveEndReading`] - (meter as any)[`forwardActiveStartReading`])
      .reduce((acc: number, current: number) => acc + current, 0),
    5
  )
)
const totalPVReverse = computeTotal(MeterType.SolarMeter, 'reverseActive');

const swapValues = (row: InvoiceMeterReadings) => {

  [row.forwardActiveStartReading, row.reverseActiveStartReading] = [row.reverseActiveStartReading, row.forwardActiveStartReading];
  [row.forwardActiveEndReading, row.reverseActiveEndReading] = [row.reverseActiveEndReading, row.forwardActiveEndReading];
};
</script>

<template>
<ModalContainer @close="$emit('close')"  :width="1082">
    <ModalTitle>
        LINKED METERS
    </ModalTitle>
    <ModalContent>
      <ContentLoader :loading="isLoading" variant="overlay">
          <HeroSplashError v-if="isError" />
            <table class="table table-no-bottom-border">
              <thead>
                <tr>
                  <th scope="col">METER</th>
                  <th scope="col">SERIAL NO.</th>
                  <th scope="col">START</th>
                  <th scope="col"></th>
                  <th scope="col">END</th>
                  <th scope="col"></th>
                  <th scope="col">DIFFERENCE</th>
                  <th scope="col"></th>
                  <th scope="col"></th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                <tr class="bold-row">
                  <td></td>
                  <td></td>
                  <td>Forward</td>
                  <td>Reverse</td>
                  <td>Forward</td>
                  <td>Reverse</td>
                  <td>Forward</td>
                  <td></td>
                  <td>Reverse</td>
                  <td>Type</td>
                </tr>
                <tr v-for="(meter, index) in meters" :key="meter.id"
                    :class="{ 'table-no-bottom-border': index === meters.length - 1 }">
                  <td>{{ meter.deviceId }}</td>
                  <td>{{ meter.serialNumber }}</td>
                  <td>
                    <InputNumber v-model:value="meter.forwardActiveStartReading"
                    :name="`meters[${index}].forwardActiveStartReading`"
                    error-variant="tooltip"
                    align="right"
                    style="width: 70%; float: right;"
                    />
                  </td>
                  <td>
                    <InputNumber v-model:value="meter.reverseActiveStartReading"
                    :name="`meters[${index}].reverseActiveStartReading`"
                    error-variant="tooltip"
                    align="right"
                    style="width: 70%; float: right;"/>
                  </td>
                  <td style="">
                    <InputNumber
                      v-model:value="meter.forwardActiveEndReading"
                      :name="`meters[${index}].forwardActiveEndReading`"
                      error-variant="tooltip"
                      align="right"
                      style="width: 70%; float: right;"
                    />
                  </td>
                  <td>
                    <InputNumber
                      v-model:value="meter.reverseActiveEndReading"
                      :name="`meters[${index}].reverseActiveEndReading`"
                      error-variant="tooltip"
                      align="right"
                      style="width: 70%; float: right;"
                    />
                  </td>
                  <td>{{ roundToPrecision(meter.forwardActiveEndReading - meter.forwardActiveStartReading, 5) }}</td>
                  <td><FontAwesomeIcon class="color-temp" :icon="faRightLeft" @click="swapValues(meter)" /></td>
                  <td>{{ roundToPrecision(meter.reverseActiveEndReading - meter.reverseActiveStartReading, 5) }}</td>
                  <td>{{ meter.meterType.name }}</td>
                </tr>
                <tr class="table-no-bottom-border" style="height: 30px;">
                  <td colspan="10"></td>
                </tr>
                <tr class="table-blue-row-no-border">
                  <td class="blue-cell text-end" colspan="6">Total Grid</td>
                  <td class="blue-cell">{{ totalGridForward }}</td>
                  <td class="blue-cell"></td>
                  <td class="blue-cell">{{ totalGridReverse }}</td>
                  <td class="blue-cell"></td>
                </tr>
                <tr class="table-blue-row-no-border">
                  <td class="blue-cell text-end" colspan="6">Total PV</td>
                  <td class="blue-cell">{{ totalPVForward }}</td>
                  <td class="blue-cell"></td>
                  <td class="blue-cell">{{ totalPVReverse }}</td>
                  <td class="blue-cell"></td>
                </tr>
              </tbody>
            </table>
        </ContentLoader>
    </ModalContent>
    <ModalFooterConfirmation
      @reject="$emit('close')"
      @confirm="handleSave()"
      :pending="isPending"
      :confirm-icon="faFloppyDisk"
      confirm-text="Save"
      reject-text="Cancel"
    />
  </ModalContainer>

</template>

<style scoped lang="scss">

.bold-row {
    font-weight: bold;
}

.table-no-bottom-border {
    border-bottom: none;
    border-color: #fff;
}


th {
    font-size: 12px;
    font-weight: bold;
    color: #6d6e71;
}

tr {
    border-color: #DDDDDD;
    text-align: end;
}

table td:nth-child(7),
table th:nth-child(7) {
    background-color: #EEEEEE;
}

table td:nth-child(8),
table th:nth-child(8) {
    background-color: #EEEEEE;
}

table td:nth-child(9),
table th:nth-child(9) {
    background-color: #EEEEEE;
}

.table-blue-row-no-border {
    border-bottom: none;
    border-color: rgba(40, 160, 156,0);
}
.table td.blue-cell  {
    background: rgba(40, 160, 156, 0.15);
}
</style>
