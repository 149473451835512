import {
  applyFilteringToUrl,
  applyPagingToUrl,
  applySortingToUrl,
  buildApiUrl,
  sendPostRequest,
  sendPutRequest,
  sendPatchRequest,
  sendPaginationRequest,
  Type,
  sendGetRequest
} from "@/features/api";
import { format } from 'date-fns/format'
import { DateFormats } from '@/helpers/formatting'
import type { SortedField } from '@/types'
import { InvoiceSchema, InvoiceConfigSchema, PaginatedInvoiceSchema, InvoiceMeterReadingsSchema, InvoiceActivitySchema } from "@/features/billing/schemas";
import { InvoiceStatus } from '@/features/billing/constants'
import { assertSchema } from "@/helpers/assert-schema";
import { z } from "zod";


export async function approveInvoice(invoiceId: number) {
  const url = buildApiUrl(Type.AssetManagement, `billing/invoices/${invoiceId}/approvals`)

  await sendPutRequest(url)
}


export async function regenerateInvoice(invoiceId: number) {
  const url = buildApiUrl(Type.AssetManagement, `billing/invoices/${invoiceId}/regenerate`)

  // Endpoint always returns `true` and will throw an error if there's an issue.
  await sendPostRequest(url)
}


export async function recreateInvoice(invoiceId: number) {
  const url = buildApiUrl(Type.AssetManagement, `billing/invoices/${invoiceId}/recreate`)
  await sendPostRequest(url)
}


export async function sendInvoice(invoiceId: number) {
  const url = buildApiUrl(Type.AssetManagement, `billing/invoices/${invoiceId}/send`)

  await sendPostRequest(url)
}


export async function cancelInvoice(invoiceId: number) {
  const url = buildApiUrl(Type.AssetManagement, `billing/invoices/${invoiceId}/cancelled`)

  await sendPutRequest(url)
}


export async function getInvoiceActivity(invoiceId: number) {
  const url = buildApiUrl(Type.AssetManagement, `billing/invoices/${invoiceId}/activity`)

  const { data } = await sendGetRequest(url)

  assertSchema(data, z.array(InvoiceActivitySchema))

  return data
}


export async function getPaginatedInvoices(params: Partial<{
  page: number
  pageSize: number
  sort: SortedField[]
  statuses: InvoiceStatus[]
  customerIds: number[]
  startAt: Date
  endAt: Date
  filter: string
}>) {
  const url = buildApiUrl(Type.AssetManagement, 'billing/invoices')

  applyPagingToUrl(url, params.page, params.pageSize)
  applyFilteringToUrl(url, params.filter)
  applySortingToUrl(url, params.sort)

  if (params.customerIds !== undefined && params.customerIds.length > 0) {
    url.searchParams.set('customers', params.customerIds.join(','))
  }

  if (params.startAt !== undefined) {
    url.searchParams.set('start', format(params.startAt, DateFormats.YearMonthDay))
  }

  if (params.endAt !== undefined) {
    url.searchParams.set('end', format(params.endAt, DateFormats.YearMonthDay))
  }

  if (params.filter !== undefined && params.filter.trim().length > 0) {
    url.searchParams.set('filter', params.filter.trim())
  }

  if (params.statuses !== undefined && params.statuses.length > 0) {
    url.searchParams.set('status', params.statuses.map(x => x.valueOf()).join(','))
  }

  const { paging, records } = await sendPaginationRequest(url, PaginatedInvoiceSchema)

  return {
    paging,
    records: records.map(
      x => ({
        ...x,
        previewUrl: buildApiUrl(Type.AssetManagement, `billing/invoices/${x.id}/preview`),
        downloadUrl: buildApiUrl(Type.AssetManagement, `billing/invoices/${x.id}/files`),
      })
    )
  }
}

export async function getInvoiceConfig(assetCode: number){
  
  const url = buildApiUrl(Type.AssetManagement, `assets/${assetCode}/billings/invoices/dummies`)

  const { data } = await sendGetRequest(url)

  assertSchema(data, InvoiceConfigSchema)

  return data
}

type billingConfig = {
  id: number,
  showMeterReadings: boolean,
  showSavings: boolean
}

export async function saveInvoiceConfig(assetCode: number, billingConfig : billingConfig){
 
  const url = buildApiUrl(Type.AssetManagement, `assets/${assetCode}/billing/invoices/billing-configurations/${billingConfig.id}`)

  await sendPatchRequest(url, { data: billingConfig })
}

export async function getInvoice(invoiceId: number){

  const url = buildApiUrl(Type.AssetManagement, `billing/invoices/${invoiceId}?includeCustomer=true`)

  const { data } = await sendGetRequest(url)

  assertSchema(data, InvoiceSchema) 

  return data
}

type InvoiceChargeQuantity = {
  id: number,
  quantity: number
}

export async function saveCharges(invoiceId: number, invoiceCharges : InvoiceChargeQuantity []){
    const url = buildApiUrl(Type.AssetManagement, `billing/invoices/${invoiceId}/charges`)
    await sendPatchRequest(url ,{ data: invoiceCharges })
}

type InvoiceConfig = {
  invoiceId: number,
  showMeterReadings: boolean,
  showSavings: boolean,
  poNumber: string
}
export async function updateInvoiceConfig(invoiceConfig : InvoiceConfig){
  const url = buildApiUrl(Type.AssetManagement, `billing/invoices/${invoiceConfig.invoiceId}/configurations`)
  await sendPatchRequest(url,{ data: invoiceConfig })
}

type InvoiceMeterReading = {
  id: number,
  deviceId: string,
  forwardActiveStartReading: number,
  forwardActiveEndReading: number,
  reverseActiveStartReading: number,
  reverseActiveEndReading: number,
  hide: boolean
}

export async function updateInvoiceMeterReadings(invoiceId: number, invoiceMeterReading: InvoiceMeterReading[], updatingConfig: boolean){
  const url = buildApiUrl(Type.AssetManagement, `billing/invoices/${invoiceId}/meters?updatingConfig=${updatingConfig}`)
  await sendPutRequest(url, { data: invoiceMeterReading })
}

export async function getInvoiceMeters(invoiceId : number){
  const url = buildApiUrl(Type.AssetManagement, `billing/invoices/${invoiceId}/meters`)
  const { data } = await sendGetRequest(url)

  assertSchema(data, z.array(InvoiceMeterReadingsSchema))

  return data
}